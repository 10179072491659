.contentImage {
    padding-bottom: 27px;
    width: 100%;
}

.titleBlock {
    width: 100%;
    height: 156px;
    border-radius: 10px;
    background-color: #0071C0;
    margin-bottom: 65px;
}

.title {
    max-width: 935px;
    font-family: "inter", sans-serif;
    text-align: center;
    font-size: 32px;
    font-weight: bold;
    line-height: 39px;
}