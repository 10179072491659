.footer {
    display: flex;
    align-items: center;
    width: 100%;
    height: 102px;
    background-color: #313131;
}

.navbar {
    display: flex;
    align-items: center;
    width: 100%;
    height: 102px;
    background-color: #313131;
}

.navbarContent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 93.75%;
    margin: 0 auto;
}

.text {
    font-family: "inter", sans-serif;
    font-size: 24px;
    color: white;
}