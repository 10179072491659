.navbar {
  display: flex;
  align-items: center;
  width: 100%;
  height: 102px;
  background-color: #313131;
}

.navbarContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 93.75%;
  margin: 0 auto;
}

.navlist {
  display: flex;
  list-style: none;
  color: white;
}

.navlistItem {
  text-transform: uppercase;
  font-family: "inter", sans-serif;
  font-size: 24px;
}

.navlistItem:not(:first-child) {
  margin-left: 65px;
}

.header__special {
  margin-top: 65px;
}

.active {
  display: block;
  position: relative;
}

.active:after {
  position: absolute;
  top: 59px;
  content: '';
  display: block;
  height: 7px;
  background-color: #0071C0;
  width: 100%;
}