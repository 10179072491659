@font-face {
    font-family: "inter";
    font-style: normal;
    font-weight: 400;
    src: local("inter"),
    url("./assets/fonts/inter.woff") format("woff")
}

@font-face {
    font-family: "inter";
    font-style: normal;
    font-weight: 700;
    src: local("inter"),
    url("./assets/fonts/inter_bold.woff") format("woff")
}

* {
    padding: 0;
    margin: 0;
    text-decoration: none;
}

a {
    color: white;
}

.white-color {
    color: white;
}

.full-center-flex {
    display: flex;
    justify-content: center;
    align-items: center;
}

.section-content {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: 66.2%;
    max-width: 1271px;
    padding-top: 48px;
    padding-bottom: 64px;
}

.content-image {
    object-fit: contain;
}

.logo {
    width: 130px;
    height: 61px;
    background: url("./assets/images/logo.svg") no-repeat 50% 50%;
    background-size: contain;
}

.vector-one {
    background-image: url("./assets/images/Vector_1.svg");
}

.vector-two {
    background-image: url("./assets/images/Vector_2.svg");
}

.vector-three {
    background-image: url("./assets/images/Vector_3.svg");
}

.vector-services {
    background-image: url("./assets/images/Vector_services.svg");
}

.text {
    font-size: 24px;
    line-height: 29px;
    font-family: "inter", sans-serif;
    font-weight: bold;
}

.paragraph {
    display: flex;
}

.icon {
    width: 88px;
    min-width: 88px;
    height: 85px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
}

.mr-20 {
    margin-right: 20px;
}

.ml-20 {
    margin-left: 20px;
}

.mb-20 {
    margin-bottom: 20px;
}

.mb-30 {
    margin-bottom: 30px;
}

.mb-40 {
    margin-bottom: 40px;
}

.loading {
    display: none;
}