.about-us__content {
    display: flex;
    flex-direction: row;
}

.about-us__image {
    max-width: 524px;
    max-height: 825px;
}

.about-us__text-content {
    padding-top: 60px;
}

.about-as__subtitle {
    font-size: 48px;
    font-family: "inter", sans-serif;
    line-height: 58px;
    margin-bottom: 38px;
}

.about-us__text {
    font-family: "inter", sans-serif;
    font-size: 28px;
    line-height: 33px;
    font-weight: bold;
}