.contacts {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: calc(100vh - 204px);
}

.contacts__row {
    display: flex;
}

.contacts__block {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1058px;
    height: 233px;
    background-color: #4473C5;
}

.contacts__text {
    text-align: center;
    max-width: 635px;
    font-size: 42px;
    line-height: 53px;
    font-family: "inter", sans-serif;
}

.contacts__avatar {
    width: 196px;
    height: 196px;
}

.contacts__avatar_one {
    background: url("../../../assets/images/contacts_1.jpg") no-repeat 50% 50% / cover;
}

.contacts__avatar_two {
    background: url("../../../assets/images/contacts_2.jpg") no-repeat 50% 50% / cover;
}